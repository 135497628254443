import React from 'react';
import { postsStyle } from '../assets/jss/pages/postsStyle';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { DefaultHeader } from '../components/Header/Header';
import { Parallax } from '../components/Parallax/Parallax';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { Breadcrumbs } from '../components/Breadcrumbs/Breadcrumbs';
import { Helmet } from 'react-helmet';
import { graphql, PageRendererProps } from 'gatsby';
import { CategoryNode } from '../interfaces/contentful';
import { createCategoryTree } from '../helpers/treeHelper';
import backgroundImage from '../assets/img/bg9.jpg';

const useStyles = makeStyles(postsStyle);

const ThankYouPage: React.FC<ThankYouPageProps> = ({ data }) => {
  const { categories } = data;

  const categoryTree = createCategoryTree(categories);

  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.blogPostPage}>
      <Helmet>
        <title>{intl.formatMessage({ id: 'breadcrumbs.thankYouPage' })}</title>
      </Helmet>
      <DefaultHeader categoryTree={categoryTree} />

      <Parallax image={backgroundImage} className={classes.parallax}>
        <div className={classes.container}>
          <Breadcrumbs>
            <Typography color="secondary">
              <FormattedMessage id="breadcrumbs.thankYouPage" defaultMessage="Thank You Page" />
            </Typography>
          </Breadcrumbs>
        </div>
      </Parallax>

      <div className={classNames(classes.section)}>
        <div className={classes.container}>
          <div className={classNames(classes.main, classes.mainRaised)}>
            <Typography variant="h5" component="h2" gutterBottom>
              <FormattedMessage id="thankyou.title" defaultMessage="Thank you for placing an order" />
            </Typography>
            <p>
              <FormattedMessage id="thankyou.text1" defaultMessage="Our team is busy preparing it for you." />
            </p>
            <p>
              <FormattedMessage
                id="thankyou.text2"
                defaultMessage="While waiting, feel free to explore our blog posts."
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ThankYouPage;

export const thankYouQuery = graphql`
  query {
    categories: allContentfulCategory {
      edges {
        node {
          slug
          title
          contentful_id
          parentCategory {
            slug
            contentful_id
          }
          regionalproduct {
            contentful_id
          }
        }
      }
    }
  }
`;

interface ThankYouPageProps extends PageRendererProps {
  data: {
    categories: {
      edges: { node: CategoryNode }[];
    };
  };
}
